@import "../variables.module";

.container {
  width: 450px;
  // background-color: $dark-one;
  border-radius: 10px;

  @media (max-width: 500px) {
    width: 100%;
  }

  h2 {
    // text-transform: capitalize;
    padding-bottom: 0;
  }
  .user-overview {
    display: flex;
    gap: 8px;
    margin-top: 28px;
    align-items: flex-end;
    .user-public-id {
      display: flex;
      gap: 8px;
      p {
        color: rgba(232, 229, 255, 0.3);
        font-size: 13px;
        font-style: normal;
        line-height: normal;
      }
      span {
        display: inline;
        width: 200px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        overflow: hidden;
        color: rgba(232, 229, 255, 0.5);
        text-overflow: ellipsis;
        font-size: 13px;
        font-style: normal;
        line-height: normal;
      }
    }
  }
  .user-display {
    padding: 20px 0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-left: 0;
    border-right: 0;
    .user-detail {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h2 {
        font-size: 15px;
        color: #fff;
        font-weight: 600;
        line-height: normal;
        // padding-bottom: 5px;
      }
      p {
        font-size: 12px;
        color: $fontColorTwo;
        font-weight: 600;
        line-height: 100%;
      }
      .user-rank {
        font-size: 13px;
        font-weight: 600;
        line-height: 100%;
        color: #cb6a4b;
        text-transform: uppercase;
      }
    }
  }

  .overview-container {
    width: 100%;
    display: flex;
    gap: 10px;

    .overview-section {
      border-radius: $radius;
      background-color: $btnFill;
      border: 1px solid $btnStroke;
      height: 71px;
      width: 100%;
      display: flex;
      align-items: center;

      .icon {
        width: 35px;
        margin-left: $padding;
        margin-right: 15px;

        svg path {
          fill: #5e679e;
        }
      }
      .text-detail {
        display: flex;
        flex-direction: column;
        gap: 8px;
        h3 {
          color: rgba(232, 229, 255, 0.5);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 12px */
          font-family: $primaryFontBold;
        }
        h2 {
          font-size: 16px;
          // color: $gold;
          font-weight: 500;
          line-height: 1em;
        }
        .value {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
    .privacy-setting {
      display: flex;
      padding: 20px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid rgba(232, 229, 255, 0.1);
      background: #202645;

      p {
        color: #e8e5ff;
        font-size: 12px;
        font-style: normal;
        line-height: 150%; /* 18px */
      }
    }
  }
  .user-action {
    display: flex;
    gap: 16px;
  }
}

@media screen and (max-width: $breakPointThree) {
  .user-overview {
    .user-public-id {
      flex-direction: column;
    }
  }
  .user-display {
    gap: 0;
    .rank-container {
      display: none;
    }
  }
  .overview-container {
    flex-direction: column;
  }
  .user-action {
    flex-direction: column;
  }
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
