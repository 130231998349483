@import "../variables.module";

.profile-banner-container {
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: 620px;
    .reward-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title-container {
      align-items: center;
      display: flex;
      gap: 10px;
  
      .h3 {
        font-size: 20px;
        line-height: 1.5;
      }
  
      .username {
        background: linear-gradient(0deg, #3ca6ff 0%, #88f3f3 68.01%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 20px;
        letter-spacing: -0.42px;
        line-height: 1.5;
      }
  
      .svg-logo-container {
        svg {
          display: block;
          width: 26px;
        }
      }
    }
  
    .profile-container {
      backdrop-filter: blur(3px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      width: 100%;
      
      .text-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .profile-text-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          
          .profile-user-name {
            color: #FFF;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          
          .profile-text {
            color: rgba(232, 229, 255, 0.50);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; 

            .next-rank-label {
              margin-right: 7px;
              font-weight: 500;
              font-size: 12px;
              color: rgba(232, 229, 255, 0.75);
            }
          }
  
        }
    
        .wagered-text {
          align-items: center;
          color: #fff;
          display: flex;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.5;
          gap: 5px;
        }
      }
  
      .user-profile-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        .user-rank {
          color: #CB6A4B;
          font-size: 13px;
          font-weight: 600;
          line-height: 1.5;
        }
      }
  
      .progress-bar-container {
        border-radius: 40px;
        display: flex;
        padding: 5px;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;
  
        .progress-bar {
          border-radius: 70px;
          background: rgba(255, 255, 255, 0.1);
          width: 100%;
  
          .bar {
            border-radius: 30px;
            background: #CB6A4B;
            box-shadow: 0px 0px 10px 0px rgba(233, 156, 114, 0.50);
            height: 7px;
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: $breakPointThree) {
    .profile-banner-container {
  
      .title-container {
        .h3 {
          font-size: 22px;
        }
  
        .username {
          font-size: 22px;
        }
  
        .svg-logo-container {
          svg {
            width: 28px;
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: 450px) {
    .profile-banner-container {
      .title-container {
        .h3 {
          font-size: 24px;
        }
  
        .username {
          font-size: 24px;
        }
  
        .svg-logo-container {
          svg {
            width: 30px;
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: 500px) {
    .profile-banner-container {
      .title-container {
        .h3 {
          font-size: 26px;
        }
  
        .username {
          font-size: 26px;
        }
  
        .svg-logo-container {
          svg {
            width: 32px;
          }
        }
      }
    }
  }
  
  @media only screen and (min-width: 550px) {
    .profile-banner-container {
      .title-container {
        .h3 {
          font-size: 28px;
        }
  
        .username {
          font-size: 28px;
        }
  
        .svg-logo-container {
          svg {
            width: 34px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    
    .profile-container {  
      .text-container {
        flex-direction: column;
        gap: 12px;
        
        .profile-text-container {
          flex-direction: column;
          gap: 8px;
        }
      }
      .next-rank-label {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakPointThree){
    .profile-banner-container {

      .reward-container {
        display: none;
      }
    }
  }