@import "../variables.module";

.container {
  width: 450px;
  // background-color: $dark-one;

  @media (max-width: 500px) {
    width: 100%;
  }
  border-radius: 10px;
  h2 {
    // text-transform: capitalize;
    padding-bottom: 0;
    font-family: $primaryFontBold;
    font-size: 20px;
    line-height: normal;
    font-style: normal;
  }
  .user-public-id {
    display: flex;
    gap: 8px;
    padding: 4px 0px;
    align-items: center;
    align-self: stretch;
    margin-top: 28px;
    .user-public-container {
      display: flex;
      gap: 8px;
      font-weight: 500;
    }
    .title {
      color: rgba(232, 229, 255, 0.3);
      font-size: 13px;
      font-style: normal;
      line-height: normal;
    }
    .id {
      display: inline-block;
      width: 200px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      overflow: hidden;
      color: rgba(232, 229, 255, 0.5);
      text-overflow: ellipsis;
      font-size: 13px;
      font-style: normal;
      line-height: normal;
    }
  }
  .user-display {
    padding: 20px 0;
    width: 100%;
    margin: 20px 0;
    // background-color: $sixthBackground;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-right: 0;
    border-left: 0;
    .left {
      margin-top: 10px;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      h2 {
        font-size: 18px;
        // padding-bottom: 5px;
        font-family: $primaryFontBold;
      }
      p {
        font-size: 13px;
        color: $fontColorTwo;
        font-family: $primaryFontMedium;
      }
    }

    .rank-row {
      display: flex;
      align-items: center;
      gap: 10px;
      .rank-container {
        width: 30px;
      }
    }
  }

  .overview-container {
    width: 100%;
    display: flex;
    gap: 16px;

    .overview-section {
      border-radius: $radius;
      background-color: #202645;
      height: 71px;
      width: 100%;
      display: flex;
      align-items: center;

      .icon {
        width: 35px;
        margin-left: $padding;
        margin-right: 15px;

        svg path {
          fill: #5e679e;
        }
      }
      .text-detail {
        span {
          font-weight: 400;
          color: #fff;
        }
        h3 {
          color: rgba(232, 229, 255, 0.5);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          padding-bottom: 8px;
          font-family: $primaryFontBold;
        }
        h2 {
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
          line-height: 1em;
          font-weight: 500;
        }
        .value {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .user-public-container {
    flex-direction: column;
  }

  .overview-container {
    flex-direction: column;
  }
}
@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
