@import "../variables.module";

.auth-container {
    width: 431px;
    // background-color: $dark-one;
    border-radius: 10px;
    @media (max-width: $breakPointTwo) {
        width: 100%;
    }

    &.active-two-factor {
        width: 280px;
        transition: width 200ms $easeInOut3;

        @media (max-width: $breakPointTwo) {
            width: 100%;
        }
    }
    h2 {
        text-transform: capitalize;
        padding-bottom: 0;
    }

    .alt-signin {
        padding-top: $padding;
        margin-top: $padding;
        border-top: 1px solid $lightBackgroundColor;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;

        .alt-description {
            
            h3 {
                font-size: 13px;
                color: $fontColorFour;
            }
            p {
                padding-top: 3px;
                font-size: 13px;
                color: #5E678E;
            }
        }

        .btn-content {
            gap: 10px;
            display: flex;

        }
    }
}
.twoFactor {

    padding-top: 20px;

    p {
        line-height: 1.1em;
        font-size: 12px;
        color: $fontColorTwo;
        padding-top: 10px;
        padding-bottom: 20px;
    }

}
.steam-button {
    padding-top: 20px; 
    padding-bottom: 20px; 
}
.form-container {
    padding-top: 20px; 
}
.instructions-block  {
    // background-color: $lightBackgroundColor;
    border-radius: $radius;
    // padding: 15px; 
    margin-bottom: 20px; 
    //  display: flex;
    // gap: 5px; 
    p {
        width: 100%;
        font-size: 12px; 
        line-height: 1.3em;
        display: inline-flex;
        align-items: center;
        gap: 3px; 
        color: $fontColorTwo;
        svg {
            // margin-right: 5px;
            line-height: 1em;
        }
    }

    ul {
        font-size: 12px;
        color: $fontColorTwo;
        line-height: 1.5em;
        margin-left: 17px;
        padding-top: 10px; 
    }

    a {
        color: #a0a7ca;
    }
}
.nav {

}

@media (max-width: $breakpoint-one) {
 
}

@media (prefers-color-scheme: dark) {
  
}
