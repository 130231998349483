@import "../variables.module";

.wallet-settings-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 477px;
  border-radius: $radius;

  h3 {
    font-size: 15px;
  }

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  .heading {
    display: flex;
    gap: 10px;
  }

  .list-of-currencies {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 100px);
    justify-items: center;
    justify-content: space-between;
    padding: 25px;
    padding-bottom: 0;

    @media (max-width: $breakPointTwo) {
      width: 100%;
      grid-template-columns: repeat(2, 120px);
    }
  }

  .radio-currency-select {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    margin-bottom: 20px;

    // width: 150px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    input {
      margin: 0px;
    }

    .curreny-icon-container {
      width: 18px;
      height: 18px;
      position: relative;

      &:hover {
        cursor: pointer;
      }
    }

    input[type="radio"] {
      opacity: 0;
      position: absolute;
      margin: 0;
    }

    .currency-label {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      // width: 100px;
      width: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-text {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 60px;

      span {
        font-size: 14px;
      }
    }

    .custom-radio-button {
      position: relative;
      display: inline-flex;
      border: 1px solid #ffffff10;
      background-color: #0f1328;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;

      .custom-radio-button-inner {
        // position: absolute;
        // top: 1px;
        // left: 1px;

        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #0077db;
        transform: scale(0);
        transition: transform 0.2s;
      }
    }

    &.active-radio {
      .custom-radio-button {
        border: 1px solid #0078db6d;

        .custom-radio-button-inner {
          transform: scale(1) !important;
        }
      }
    }
  }

  h2 {
    padding-bottom: 20px;
  }

  button {
    margin: 10px 0 10px 0;
  }

  .wallet-settings-description {
    background-color: #ff935425;
    border-radius: $radius;
    padding: 20px;
    margin-top: 20px;

    p {
      color: #ff9354;
      font-size: 12px;
      text-align: center;
    }
  }

  .beta-banner {
    display: inline-block;

    // width: 50px;
    background-color: #0077db;
    border-radius: $radius;
    padding: 3px 10px;

    text-align: center;
    font-weight: 600;
    font-size: 11px;
    transform: translateY(-2px);
  }
}

@media (max-width: $breakpoint-one) {
}

@media (prefers-color-scheme: dark) {
}
