@import "../variables.module";

.container {
  width: $modalWidth;
  // padding: $Dmw/2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  .toggle-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // align-items: center;
    padding-bottom: $padding;

    .toggle-row {
      display: flex;
      justify-content: space-between;

      .toggle-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;

        p {
          font-size: 13px;
          color: $fontColorSix;
          font-family: $primaryFontMedium;
        }
      }
    }
  }

  h3 {
    font-size: 15px;
    padding-bottom: 8px;
  }
}

.settings-block {
  padding-top: $Dmw/2;

  // padding-bottom: 20px;
  h3 {
    font-size: 15px;
    padding-bottom: 8px;
  }

  input:first-child {
    border: none !important;
  }
}

.qr-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  gap: 20px;
}

.desc {
  flex: 1;

  h3 {
    font-size: $fontSizeThree;
  }

  p {
    font-size: 13px;
    color: $fontColorTwo;
    font-family: $primaryFontMedium;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  // Add any other styles you want for the description container.
}

.warning {
  padding-top: $padding;
  display: flex;
  gap: 10px;

  svg {
    width: 15px;
    padding-top: 2px;
  }

  p {
    font-size: 13px;
    color: $failRed;
  }
}

.image-wrapper {
  background-color: #fff;
  border-radius: $radius;
  border: 1px solid $btnBorderTwo;
  width: 134px;
  min-height: 134px;
  position: relative;
  border-radius: $radius;
  overflow: hidden;
  // flex: 1 1 10px;
  // Add any other styles you want for the image container.
}

.user-input-section {
  padding-top: $padding;
  border-top: 1px solid $btnBorderTwo;
}

.formContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  padding: 20px;
  //width: 400px;
  margin: $Dmw;
}
