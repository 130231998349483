@import "../variables.module";

.trademark {
  font-size: 11px !important;
  display: inline-block !important;
  color: #fff !important;
  transform: translate(2px, -3px) !important;
}

.trademark-two {
  font-size: 11px !important;
  display: inline-block !important;
  color: #fff !important;
  transform: translate(-2px, -3px) !important;
}

.affiliate-container {
  margin-top: 10px;

  .back-button-container {
    margin-bottom: 20px;
  }
}

.affiliate-header {
  margin-bottom: 20px;

  span {
    color: #7179a5;
    font-size: 13px;
  }
}

.mobile-back-button-container {
  margin-bottom: 20px;
}

.tab-heading {
  margin-bottom: 20px;

  h3 {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  p {
    color: $fontColorTwo;
    font-size: 13px;
  }
}

.container {
  width: 100%;
  display: flex;
  position: relative;
  // flex-direction: column;
  // justify-content: space-between;
  // background-color: $dark-one;
  // width: 520px;

  // min-width: 910px;
  // min-height: 617px;

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }

  &.hide {
    opacity: 0;
  }

  .left-side-wallet-container {
    margin-right: 10px;
    width: 500px;
    overflow: hidden;
    position: relative;
    transition:
      width 200ms $easeInOut3,
      opacity 200ms $easeInOut3 200ms,
      margin 200ms $easeInOut3;
  }

  @media (max-width: 1250px) {
    .left-side-wallet-container {
      width: 350px;
    }
  }

  .right-side-wallet-container {
    position: relative;
    width: 540px;
    margin-left: 10px;
  }

  &__hide-left-side {
    // .right-side-wallet-container {
    //   margin-left: 0px;
    // }

    .left-side-wallet-container {
      width: 0px;
      opacity: 0;
      margin-right: 0px;
      transition:
        width 200ms $easeInOut3,
        opacity 200ms $easeInOut3;
    }
  }

  @media (max-width: 1092px) {
    .right-side-wallet-container {
      margin-left: 0px;
    }
  }

  @media (max-width: $breakPointTwo) {
    .right-side-wallet-container {
      width: 100%;
    }
  }

  .overlay-container {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(25, 31, 59);
    z-index: 2;

    opacity: 1;
    pointer-events: none;
    transition:
      opacity 0.2s $easeInOut3,
      transform 0.2s $easeInOut3;
    // transform: translateY(100px);

    // &.show {
    //   transform: translateY(0px);
    //   opacity: 1;
    //   pointer-events: auto;
    // }
  }

  .withdraw-deposit-header {
    padding-bottom: 20px;

    h2 {
      padding-bottom: 20px;
    }
  }

  .default-crypto-banner {
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border-radius: $radius;
    position: relative;

    .default-crypto-banner-text {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: flex-start;
      justify-content: center;

      .center-content {
        padding-top: 20%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        gap: 90px;
        height: 100%;

        .crypto-logo {
          position: relative;
          // height: 125px;
          // width: 135px;
          // border: 1px solid #fff;
        }

        .logo-icon {
        }

        .text-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          h1 {
            font-size: 38px;
            font-weight: bold;
            background: linear-gradient(to top, #ffffff 30%, #70d9ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 20px;
          }

          h3 {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            line-height: 1.3em;

            background: linear-gradient(to top, #e8e5ff, #9ca2c4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            span {
              background: linear-gradient(to top, #ffffff, #e8e5ff);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }

    .particles-background {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.1;
      img {
        height: 100%;
      }
    }

    .default-crypto-banner-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  // min-height: 500px;
  // border-radius: $radius;
  // padding: $Dmw/2;
  // transition: width 200ms $easeInOut3 100ms;

  .wallet-select-section {
    // position: absolute;
    // top: $padding * 6;
    right: $padding;
    left: $padding;
    transition:
      opacity $tranTime $easeInOut3,
      transform $tranTime $easeInOut3;
  }

  &.active-method {
    // width: 560px;

    @media (max-width: $breakPointTwo) {
      width: 100%;
    }
  }
}

.crypto-select-dropdown {
  display: flex;
  height: 54px;
  align-items: center;
  gap: 15px;
}

.network-details-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .dropdown-column {
    width: 50%;
    transition: opacity 0.2s $easeInOut3;
    position: relative;

    h3 {
      font-size: 12px;
      color: #e8e5ffc7;
      padding-bottom: 15px;
    }

    &__only-one-network {
      opacity: 0.5;
      pointer-events: none;
    }

    &__no-network {
      opacity: 0;
    }
  }
}

.network-dropdown-btn {
  width: 100%;
  text-align: left;

  span {
    font-size: 13px;
  }
}

.crypto-dropdown-scroll-container {
  margin-top: 10px;
  max-height: 350px;

  overflow-y: auto;
  @include scrollbars(4px, #4a517d, #4a517d33);
}

.dropdown-select-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;

  align-items: flex-start;
}

.btn-text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .code {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .name {
    color: #98a1c7 !important;
    font-size: 10px;
  }
}

.withdraw-container {
  width: 100%;
  height: 100%;
  transition:
    opacity $tranTime $easeInOut3,
    transform $tranTime $easeInOut3;
  background: linear-gradient(to top, #14182f, #12162b);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: $radius;
  padding: $padding;
  pointer-events: auto;

  .withdraw-details {
    position: relative;
  }
}

.withdraw-details-container {
  position: relative;

  .withdraw-address-content {
    transition: opacity 0.2s $easeInOut3;
  }

  &__hide {
    .withdraw-address-content {
      pointer-events: none;
      opacity: 0;
    }

    .select-crypto-message {
      opacity: 1;
      top: 160px;
    }
  }
}

.network-details {
  font-size: 12px;
  padding-top: $padding;

  p {
    text-align: center;
    color: $fontColorTwo;
  }

  .fee {
    font-family: $boldFont;
    color: $fontColorTwo;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .value {
      display: inline-block;
      color: $fontColorTwo;
      display: flex;
      align-items: center;
      gap: 5px;

      div {
        padding-top: 3px;

        svg {
          transform: translateY(-1.5px);
        }
      }
    }
  }

  &.clickable {
    justify-content: center;
    display: flex;

    p {
      width: fit-content;
      text-align: center;
      cursor: pointer;
    }
  }
}

.select-crypto-message {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 130px;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s $easeInOut3;

  h3 {
    font-size: 13px;
    color: #98a1c7;
  }
}

.deposit-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition:
    opacity $tranTime $easeInOut3,
    transform $tranTime $easeInOut3;
  background: linear-gradient(to top, #14182f, #12162b);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: $radius;
  padding: $padding;
  pointer-events: auto;
  display: block;

  .deposit-details {
    // padding-top: $padding;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding-top: $Dmw/2;

    .address-details {
      position: relative;

      &__no-crypto-selected {
        .select-crypto-message {
          opacity: 1;
        }

        .address-content {
          opacity: 0;
        }
      }

      .address-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        transition: opacity 0.2s $easeInOut3;
      }

      .qr-container {
        position: relative;
        background-color: #fff;
        padding: 10px;
        height: 125px;
        width: 125px;
        border-radius: $radius;
        display: inline-block;
        display: flex;
        overflow: hidden;
        justify-content: center;

        svg {
          height: 85px;
          width: 85px;
        }
      }

      .address {
        flex: 1;
        width: 100%;
      }

      @media (max-width: $breakPointOne) {
        flex-direction: column;

        .qr-container {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.crypto-details {
  padding: $padding;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: rgba(255, 146, 84, 0.1);
  border-radius: $radius;
  // border: 1px solid #fff;
  min-height: 80px;

  .text {
    font-size: 12px;
    line-height: 1.2rem;
    text-align: center;
    color: #7179a5;

    &__loading {
      color: #7179a5;
    }

    span {
      font-size: 13px;
      color: #ff5e54;
    }
  }
}

.input-crypto-icon {
  position: absolute;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-top: 11px;
}

.buy-crypto-container {
  .continue-btn-loader {
    display: flex;
    gap: 10px;
    align-items: center;

    span {
      font-size: 13px;
    }

    svg path {
      fill: #fff;
    }
  }

  h2 {
    font-size: 15px;
    padding-bottom: 15px;
  }

  p {
    color: $fontColorTwo;
    font-size: 13px;
  }

  .description {
    // border-bottom: 1px solid #262c52;
    padding-bottom: 15px;

    h3 {
      margin-bottom: 15px;
    }
  }

  .instruction-area {
    .step-header {
      padding-top: 20px;
      display: flex;
      font-size: 13px;
      align-items: center;
      gap: 11px;
      margin-bottom: 20px;

      .num {
        font-size: 11px;
        color: $fontColorTwo;
        font-family: $boldFont;
        height: 20px;
        width: 20px;
        background-color: #0077db;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .method-images-radio-container {
      span {
        margin-bottom: 15px;
        font-size: 12px;
        display: inline-block;
        color: rgba(232, 229, 255, 0.7803921569);
      }
    }

    .method-images-radio {
      // border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      gap: 10px;
      margin-top: 0px;
      margin-bottom: 20px;
      padding: 15px;
      overflow: hidden;
      border-radius: 6px;
      border: 1px solid #a752ff;
      background:
        radial-gradient(
          86.19% 100% at 50% 100%,
          rgba(167, 82, 255, 0.16) 0%,
          rgba(167, 82, 255, 0) 100%
        ),
        linear-gradient(
          270deg,
          rgba(167, 82, 255, 0.1) 51.5%,
          rgba(151, 51, 255, 0) 100%
        ),
        linear-gradient(
          180deg,
          rgba(167, 82, 255, 0.15) 0%,
          rgba(151, 51, 255, 0.15) 100%
        );
      pointer-events: none;
      flex-wrap: wrap;

      .fake-radio {
        display: flex;
        width: 18px;
        height: 18px;
        padding: 4.05px;
        justify-content: center;
        align-items: center;

        border-radius: 63px;
        border: 1.35px solid #b166ff;
        background: #1b0f28;

        .inner-radio {
          width: 9.9px;
          height: 9.9px;
          flex-shrink: 0;
          border-radius: 63px;
          background: linear-gradient(180deg, #a752ff 0%, #9733ff 100%);
        }
      }

      .method-row {
        display: flex;
        align-items: center;
        gap: 10px;

        justify-content: flex-start;

        .method-label {
          font-size: 12px;
          color: rgba(232, 229, 255, 0.78);
        }

        svg {
          width: auto;
          height: 17px;
        }

        // height: 50px;
      }

      .visa-mastercard-content {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .particles-background-buy {
        opacity: 0.3;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .step-section {
      margin-bottom: 20px;
    }
  }

  .buy-crypto-network-container {
    transition: opacity 0.2s $easeInOut3;

    &__fade-out {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .moonpay-container {
    display: flex;
    // display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    // border: 1px solid #262c52;
    .moonpay-back-btn {
      width: 100%;
    }

    .moonpay-widget {
      // border-color:  #262c52 !important;
      // width: 100% !important;
    }
  }

  .buy-terms-footer {
    font-size: 12px;
    text-align: center;

    .terms-link {
      margin-left: 4px;
      color: $fontColorTwo;
      transition: color 0.2s $easeInOut3;

      &:hover {
        color: #9aa1c5;
      }
    }
  }

  // &__active-moonpay {
  //   .description {
  //     display: none;
  //   }
  //   .buy-terms-footer {
  //     display: none;
  //   }
  //   .moonpay-container {

  //     // border: 1px solid #262c52;
  //     .moonpay-back-btn {
  //       width: 100%;
  //       margin-bottom: 20px;
  //     }
  //     .moonpay-widget {
  //       border-color:  #262c52 !important;
  //       width: 100% !important;
  //       margin: 0 !important;
  //     }
  //   }

  // }
}

.footer {
  border-top: 1px solid #262c52;
  padding-top: 15px;
  text-align: center;

  a {
    font-size: 13px;
    padding-left: 5px;
    color: #5e679e;
  }
}

.top-detail {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: $padding;

  .balance-display {
    padding: 20px;
    background-color: #262c52;
    border-radius: $radius;
    flex: 1 1 calc(50% - 5px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 13px;
      color: $fontColorTwo;
    }

    .balance {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // padding-top: 7px;
      // gap: 5px;
      font-size: 15px;
      font-family: $boldFont;
      color: $fontColor;

      .icon {
        // margin-right: 10px;
        width: 20px;
        transform: translateY(1.5px);
      }
    }

    &.half-width {
      flex: 1 1 50%;
      padding: 20px;
      flex-direction: row;
      justify-content: space-between;

      h3 {
        padding-top: 0px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}

.convert-section {
  padding-top: $padding;
  // display: flex;
  // height: 50px;

  &.reverse {
    > div {
      flex-direction: row-reverse;
    }
  }

  p {
    color: $fontColorTwo;
    font-family: $boldFont;
    padding-bottom: 10px;
  }

  > div {
    width: 100%;

    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    span {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 5px;
      font-size: 20px;
      display: block;
      height: 100%;
      align-content: center;
    }

    .label-padding {
      padding-top: 28px;
    }

    @media (max-width: $breakPointTwo) {
      flex-direction: column;
      align-items: normal;
      gap: 10px;
      // &:first-child {
      //     border: 1px solid #fff;
      //     margin-bottom: 10px;
      // }

      span {
        display: none;
      }
    }
  }

  &.withdraw-use {
    padding-top: 0;
    margin-bottom: $padding;
  }
}

.deposit-select {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex: 1 1 50%;

  @media (max-width: $breakPointTwo) {
    // flex-direction: column;
  }
}

.pix-pic-container {
  display: flex;
  gap: 10px;
  // height: 82px;
  // margin-top: 10px;

  @media (max-width: $breakPointTwo) {
    // flex-direction: column;
    flex-wrap: wrap;
    height: auto;
  }
}

.interac-container {
  width: calc(50% - 5px);

  @media (max-width: $breakPointTwo) {
    width: 100%;
  }
}

.interac-terms {
  padding-top: $padding;
  font-size: 12px;
  color: $fontColorTwo !important;
  text-align: center;

  p {
    color: $fontColorTwo !important;
  }

  a {
    color: $fontColorTwo;
    text-decoration: underline;
  }
}

.inner-btn-content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  // justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;

    span {
      color: $fontColorTwo;
      font-size: 11px;
    }

    h3 {
      font-size: 14px;
    }
  }
}

.inner-btn-content-interac-withdraw {
  width: 100%;
  display: flex;
}

.inner-interac-withdraw {
  width: 20%;
  margin-top: 4px;
  display: block !important;
  margin-left: -7px;
}

.inner-interac-withdraw-smaller {
  width: 21%;
  margin-top: 4px;
  display: block !important;
}

.method-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  width: 100%;

  h3 {
    font-size: 14px;
    padding-bottom: 0px;
  }

  &__code-name {
    flex-direction: column;
    align-items: flex-start;

    h3 {
      font-size: 13px;
    }
  }

  .crypto-name {
    font-size: 10px;
    color: #98a1c7;
    width: auto !important;
  }

  p,
  h4 {
    line-height: 1.1em;
    color: $fontColorThree;
    font-family: $boldFont;
    font-size: 13px;
    padding-bottom: 4px;
  }

  h4 {
    padding-bottom: 0;
    // transform: translateY(0.5px);
  }

  .value {
    font-family: $boldFont;
    color: #98a1c7;
    gap: 3px;
    // border: 1px solid #fff;
    display: flex;
    // width: 10px;
  }

  &.rs-gold {
    h3 {
      padding-bottom: 0px;
      font-size: 14px;
      color: #fff;
    }

    span {
      font-size: 12px;
      color: #98a1c7;

      &:hover {
        color: #98a1c7;
      }
    }
  }

  &:hover {
    .value {
      color: #98a1c7;
    }
  }

  .single {
    flex: 1 1 60%;
  }
}

.add-crypto-section {
  display: flex;
  gap: 15px;

  a {
    background-color: $btnFill;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 50%;

    background-color: $btn-fill-color;
    transition: background-color $tranTime $easeInOut3;

    &:hover {
      background-color: $btn-hover-state-color;
    }
  }
}

.other-method-select-container {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }

  &__cards {
    margin-top: 20px;
  }

  .other-row {
    display: flex;
    gap: 10px;

    //Remove when csgo is added
    padding-right: 5px;

    &.half-width {
      width: 50%;

      @media screen and (max-width: 769px) {
        width: 100%;
      }
    }

    @media (max-width: $breakPointTwo) {
      flex-direction: column;
      width: 100%;
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  padding: 20px;
  //width: 400px;
  margin: $Dmw;
}

.public-toggle {
  margin-bottom: 20px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: $primaryFontColor;
  }
}

.promotional-select-container {
  padding-top: 20px;

  .promotional-select-container__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    > h3 {
      font-size: 14px;
      color: $fontColorTwo;
    }
  }

  .promo-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .promotional-select-container__item {
    border: 1px solid $btnFill;
    border-radius: $radius;
    padding: 18px;
    background-color: #1c223e;
    display: flex;
    width: 100%;
    gap: 20px;

    &.active-promo {
      border: 1px solid $gold;

      //Default behaviour
      .desc .cancel-promo {
        display: block;
      }

      &:hover {
        cursor: default;
      }
    }

    &.not-deposit {
      border: 1px solid $gold;

      .desc .change-promo {
        display: block;
      }
    }

    &.darken {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
      // border: 1px solid $btnFill;
    }

    .desc {
      h3 {
        font-size: 14px;
        padding-bottom: 5px;
        color: #c1c4dc;
      }

      p {
        font-size: 13px;
        color: $fontColorTwo;
        line-height: 1.2em;

        span {
          color: $fontColorTwo;
          font-family: $mainFont;
        }
      }

      text-align: left;

      .cancel-promo,
      .change-promo {
        display: none;
        margin-top: 10px;
      }

      .change-promo {
        button {
          margin-top: 5px;

          span {
            // font-family: "Metropolis-Regular";
            font-size: 13px;
            color: #98a1c7;

            &:hover {
              color: #c1c4dc;
            }
          }
        }
      }

      .cancel-confirm {
        margin-top: 20px;
        gap: 10px;

        p {
          font-size: 13px;
        }

        > div {
          margin-top: 18px;
          gap: 10px;
          display: flex;
          justify-content: space-between;
        }

        .brighter,
        .brighter-not-bold {
          color: #c1c4dc;
          margin-top: 5px;
          margin-bottom: 10px;
        }

        .brighter-not-bold {
          font-family: $mainFont;
        }
      }

      .games-disabled {
        font-size: 13px;
        color: #c1c4dc;
        margin-top: 5px;
      }
    }
  }
}

.site-rules-promotions-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;
  gap: 18px;

  @media screen and (max-width: 769px) {
    flex-direction: column;
    display: flex;
  }

  .promotional-select-container__header {
    h3 {
      margin-top: 0px;
    }
  }

  .promotional-select-container {
    .promotional-select-container__item {
      pointer-events: none;

      .promotional-select-container__header {
        padding-bottom: 0px;
      }

      h3 {
        margin-top: -7px;
      }

      p {
        margin-top: 0px;
        font-size: 13px;
      }
    }

    .how-to-activate-container {
      margin-top: 12px;

      p {
        &:first-of-type {
          color: #7179a5;
          font-size: 13px;
          font-style: normal;
          // font-weight: 600;
          line-height: normal;
          // font-family: "Metropolis-Semi";
          margin-bottom: 4px;
        }
      }
    }
  }
}

.tab-link {
  margin-bottom: 20px;

  > * {
    min-width: 100%;
  }
}

.sync-container {
  border-top: 1px solid #1b213e;
  margin-top: 23px;
  padding-top: 20px;

  .eth-sync {
    line-height: 1.3em;
    margin-top: 10px;
    width: 70%;
    margin: auto;
    color: $fontColorTwo;
    font-size: 13px;
    text-align: center;

    button {
      background: none;
      border: none;
      color: #98a1c7;
      display: inline;
      font-size: 13px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.buy-crypto-tab {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h4 {
    color: #98a1c7;
    font-size: 14px;
    // margin-bottom: 10px;

    color: #e8e5ff;
    font-size: 13px;
  }

  .payment-methods-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.active-promo-bar-container {
  gap: 1px;
  // padding: 0px 20px;
  // background-color: #262c52;
  border-radius: 6px;
  display: flex;
  margin: 20px 0px 0px;
  // height: 50px;
  width: 100%;
  align-items: center;
  gap: 10px;
  justify-content: center;

  .text {
    font-size: 13px;
    color: #7179a5;

    svg {
      transform: translateY(3px);
    }

    span {
      color: $primaryFontColor;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    transform: translateY(1px);
  }
}

.crypto-title {
  font-size: 15px;
  margin-bottom: 15px;

  color: #e8e5ff;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 15px;

  span {
    flex-grow: 1;
    // width: 20%;
  }

  .title-line {
    height: 1px;
    background-color: #5e679e4a;
    width: 100%;
    display: inline-block;
    flex-shrink: 1;
    flex-grow: 0;
  }
}

.redeem-btn {
  position: absolute;
  z-index: 4;
  border: 1px solid #fff;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  background: radial-gradient(
    ellipse farthest-corner at 50% 100%,
    #7ef98a9c,
    #7ef98a34
  );
  padding-left: 17px;
  padding-right: 17px;
  transition: background-color 0.2s $easeInOut3;
  justify-content: space-between;

  svg path {
    fill: #7ef98b;
  }

  span {
    font-size: 12px;
    color: #7ef98b;
  }

  .link-icon-text {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .warning-message {
    display: flex;
    align-items: center;

    span {
      font-size: 10px;
    }
  }

  &:hover {
    //  background: radial-gradient(ellipse farthest-corner at 50% 100%,
    //  #7ef98ad6,
    //  #7ef98a34);
    background-color: #7ef98a27;
  }
}

.visa-icon,
.visa-icon-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: #00579f;
  width: 61px;
  height: 34px;
}

.visa-icon-buy {
  width: 40px;
  height: 25px;
}

.master-icon,
.master-icon-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: #fff;
  width: 61px;
  height: 34px;
}

.master-icon-buy {
  width: 40px;
  height: 25px;
}

.apple-icon,
.apple-icon-buy {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: #0f0f0f;
  width: 61px;
  height: 34px;
}

.apple-icon-buy {
  width: 45px;
  height: 25px;
}

.google-icon-buy {
  width: 45px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: #f7f7f7;
}

.google-icon {
  svg {
    path {
      transform: translateY(2px);
    }
  }
}

.deposit-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: -webkit-fill-available;

  .promo-code {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    z-index: 4;
    line-height: 1.5;
    font-size: 12px;

    span:first-of-type {
      color: $lightGreen;
      font-weight: 600;
    }
  }

  .background-banner {
    position: absolute;
    z-index: -1;
    top: -400px;
    left: -30px;
    right: -30px;
    bottom: -30px;
  }

  .particles-background {
    position: absolute;
    z-index: 0;
    top: -50px;
    left: -30px;
    right: -30px;
    bottom: -30px;
    opacity: 0.3;
    img {
      height: 100%;
    }
  }

  &:hover {
    .deposit-buttons-linear-background {
      background: none;
    }
  }

  .deposit-buttons-left-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 1;

    .deposit-buttons-upper-text {
      color: #e8e5ff;
      font-size: 11px;
      text-align: left;
      margin-bottom: 3px;
      margin-top: 5px;
    }

    .deposit-buttons-title-text {
      color: #fff;
      font-size: 14px;

      svg {
        width: 16px;
        transform: translateY(2.5px);
        margin-right: 5px;
      }
    }

    .deposit-buttons-powered-by {
      span {
        color: #98a1c7;
        font-size: 9px;
      }

      color: #98a1c7;
      font-size: 9px;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .deposit-buttons-crypto-image-container {
    display: flex;
    flex-direction: column;

    .deposit-buttons-payment-image {
      background: linear-gradient(90deg, #262c52 0%, rgba(38, 44, 82, 0) 100%);
      position: absolute;
      height: 109px;
      width: 200px;
      left: 20px;
    }

    .deposit-buttons-crypto-logos-container {
      display: grid;
      grid-template-columns: repeat((2, 1fr));
      gap: 5px;
    }

    @media (max-width: 466px) {
      scale: 0.7;
    }
  }

  .deposit-buttons-crypto-image-gift-container {
    position: absolute;
    right: -67px;
    top: -35px;
    transform: rotate(5.747deg);
    opacity: 0.75;
    z-index: 2;

    @media screen and (max-width: 466px) {
      right: -180px;
      z-index: 0;
    }

    .image-gift-background {
      height: 109px;
      position: absolute;
      width: 221px;
      z-index: 999;
    }

    .gift-image-line {
      display: flex;
      gap: 8px;
      margin: 8px 0px;
      justify-content: center;
    }

    .paysafe-icon {
      border-radius: 3px;
      background: #008ac9;
      justify-content: center;
      display: flex;
      align-items: center;
      width: 78px;
      height: 38px;
    }

    .master-icon {
      width: 78px;
      height: 38px;
      background: #fff;
      border-radius: 3px;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
}

.picpix-container {
  // margin-top: 20px;
  position: relative;

  .amount-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .step-two-header {
    display: flex;
    gap: 20px;

    // border-bottom: 1px solid #262c52;

    padding-bottom: 20px;
    padding-top: 20px;

    @media (max-width: $breakPointTwo) {
      flex-direction: column;

      .step-two-method-container {
        width: 100%;
      }
    }
  }

  .step-two-method-container {
    height: 80px;
    width: 50%;
    background-color: $btn-fill-color;
    display: flex;
    align-items: center;
    // justify-content: center;
    border-radius: $radius;

    .inner-btn-content {
      padding-left: 15px;
      // width: 100%;
      display: flex;
      align-items: center;
      gap: 11px;

      // justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;

        span {
          color: $fontColorTwo;
          font-size: 11px;
        }

        h3 {
          font-size: 14px;
        }
      }
    }

    .deposit-amount-container {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      gap: 7px;

      > span {
        color: $fontColorTwo;
        font-size: 11px;
      }

      .deposit-amount {
        display: flex;
        gap: 9px;

        .value-display {
          display: flex;
          gap: 5px;
          font-size: 14px;
        }
      }
    }
  }

  .final-step-container {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
    align-items: center;

    @media (max-width: $breakPointTwo) {
      flex-direction: column;
      align-items: center;
    }

    .qr-container {
      position: relative;
      border-radius: $radius;
      height: 200px;
      width: 200px;
      overflow: hidden;

      background-color: #fff;
    }

    .link-details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      @media (max-width: $breakPointTwo) {
        width: 100%;
      }

      .external-link {
        padding-left: 15px;
        padding-right: 25px;
        display: block;
        height: 50px;
        border-radius: $radius;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: radial-gradient(
          ellipse farthest-corner at 50% 100%,
          #262c51,
          #1d2341
        );

        .inline-link {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          /* or inline-block */
          max-width: 300px;
          /* Adjust as needed */

          @media (max-width: $breakPointTwo) {
            max-width: 250px;
          }

          @media (max-width: 400px) {
            max-width: 200px;
          }
        }

        span {
          max-width: 10%;
          font-size: 13px;
          // border: 1px solid #fff;
          overflow: hidden;
          display: inline-block;

          // overflow-x: hidden;
        }

        svg {
          width: 12px;

          path {
            transition: fill 200ms $easeInOut3 100ms;
            fill: #a2a1bd;
          }
        }

        &:hover {
          cursor: pointer;

          svg {
            path {
              fill: #e8e5ff;
            }
          }
        }
      }

      .details-container {
        padding: 15px;
        border-radius: $radius;
        background-color: #2e334e;

        p {
          font-size: 12px;
          color: #e8e5ff;
        }
      }

      .link-details {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  .pix-key-container {
    width: calc(50% - 10px);
  }

  .dob-container {
    width: calc(50% - 10px);
    gap: 5px;
    display: flex;
    // padding-right: 10px;
    align-items: flex-end;
    // flex: 1;
    flex-shrink: 1;
    position: relative;
    padding-top: 20px;

    .date-of-birth-header {
      font-size: 13px;
      display: block;
      color: $sub-heading-color;
      padding-bottom: 10px;
      position: absolute;
      top: 0;
    }

    @media (max-width: $breakPointTwo) {
      width: 100%;
      padding-right: 0px;
    }
  }
}

.fee-select {
  width: 100%;

  .fee-option-item {
    span {
      &:first-child {
        font-size: 13px;
        color: #e8e5ff;
      }

      &:last-child {
        font-size: 12px;
        color: #98a1c7;
        opacity: 0;
      }
    }
  }

  p {
    color: $primaryFontColor;
  }

  @media (max-width: $breakPointTwo) {
    max-width: 100%;
  }
}

.withdrawal-warning {
  p {
    margin-top: 16px;
    text-align: center;
    font-size: 10px;
    color: #98a1c7;
  }
}
